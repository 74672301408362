<nav class="navbar navbar-expand-md bg-dark border-bottom border-body" data-bs-theme="dark">
  <div class="container-fluid">
    <a class="navbar-brand">
      <svg xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#" xmlns="http://www.w3.org/2000/svg" height="45" width="70" version="1.1" xmlns:cc="http://creativecommons.org/ns#" xmlns:dc="http://purl.org/dc/elements/1.1/" viewBox="0 0 272.70001 238.5">
        <path fill="#fc0" d="m195.8 17.933c23.3 0 42.2 98.3 42.2 219.7h34c0-130.7-34.3-236.5-76.3-236.5-24 0-45.2 31.7-59.2 81.5-14-49.8-35.2-81.5-59-81.5-42 0-76.2 105.7-76.2 236.4h34c0-121.4 18.7-219.6 42-219.6s42.2 90.8 42.2 202.8h33.8c0-112 19-202.8 42.3-202.8"/>
      </svg>
      {{applicationName}}
    </a>
    <button class="navbar-toggler" type="button" aria-controls="navbarContent" [attr.aria-expanded]="!collapsed"
      aria-label="" (click)="collapsed = !collapsed">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="navbar-collapse" [class.collapse]="collapsed" id="navbarContent">
      <ul class="navbar-nav ms-auto">
        @for (menu of customMenus; track menu) {
          <li class="nav-item dropdown" ngbDropdown display="dynamic" placement="bottom-end" *hasRoles="menu.authorizedRoles">
            <a class="nav-link dropdown-toggle" ngbDropdownToggle role="button" aria-expanded="false">
              <fa-icon [icon]="menu.icon"></fa-icon>
              {{ menu.label| translate}}
            </a>
            <ul class="dropdown-menu" ngbDropdownMenu>
              @for (item of menu.menuItems; track item) {
                <li *hasRoles="item.authorizedRoles">
                  @if (item.link.startsWith("http")) {
                    <a class="dropdown-item" [attr.href]="item.link">
                      <fa-icon [icon]="item.icon"></fa-icon>
                      {{ item.label | translate }}
                    </a>
                  } @else {
                    <a class="dropdown-item" [routerLink]="item.link">
                      <fa-icon [icon]="item.icon"></fa-icon>
                      {{ item.label | translate }}
                    </a>
                  }
                </li>
              }
            </ul>
          </li>
        }
        <li class="nav-item dropdown" ngbDropdown display="dynamic" placement="bottom-end">
          <a class="nav-link dropdown-toggle" ngbDropdownToggle role="button" aria-expanded="false">
            <fa-icon [icon]="faFlag"></fa-icon>
            {{'global.menu.language' | translate}}
          </a>
          <ul class="dropdown-menu" ngbDropdownMenu>
            <li><button class="dropdown-item" (click)="changeLangClick('fr-CH')">Français</button></li>
            <li><button class="dropdown-item" (click)="changeLangClick('de-CH')">Deutsch</button></li>
            <li><button class="dropdown-item" (click)="changeLangClick('it-CH')">Italiano</button></li>
          </ul>
        </li>
        <li class="nav-item dropdown" ngbDropdown display="dynamic" placement="bottom-end" *isAuthenticated>
          <a class="nav-link dropdown-toggle" ngbDropdownToggle role="button" aria-expanded="false">
            <fa-icon [icon]="faUser"></fa-icon>
            {{ "global.menu.account.main" | translate }}
          </a>
          <ul class="dropdown-menu" ngbDropdownMenu>
            <li>
              <a class="dropdown-item" [href]="authorityServer + '/users/me?redirect_uri=' + getCurrentUri()">
                <fa-icon [icon]="faWrench" />
                {{'global.menu.account.settings' | translate}}
              </a>
            </li>
            <li>
              <a class="dropdown-item" [href]="authorityServer + '/users/me/password?redirect_uri=' + getCurrentUri()">
                <fa-icon [icon]="faClock"></fa-icon>
                {{'global.menu.account.password' | translate}}
              </a>
            </li>
            <li>
              <button class="dropdown-item" href="#" (click)="logout()">
                <fa-icon [icon]="faSignOut"></fa-icon>
                {{'global.menu.account.logout' | translate}}
              </button>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</nav>

<ngx-spinner  bdColor="rgba(0, 0, 0, 0.8)"
              size="medium"
              color="#fff"
              type="square-jelly-box"
              [fullScreen]="true"></ngx-spinner>