<div class="row mb-3">
    <div class="col-sm-3">
        <label for="storeSelect">{{ 'calendar.search.restaurant' | translate}}</label>
        <mcdo-store-select [(ngModel)]="store"></mcdo-store-select>
    </div>
    <div class="col-sm-3 offset-sm-6 d-grid d-md-dlex justify-content-end">
        <div>
            <button class="btn btn-primary" (click)="window.print()">{{'global.button.print' | translate}}</button>
        </div>
    </div>
</div>

<div class="row">
    <div class="col">
        <full-calendar [options]="calendarOptions" [events]="events"></full-calendar>
    </div>
</div>