<h3>{{ 'dashboard.search.title' | translate }}</h3>
<form [formGroup]="searchForm">
    <div class="row justify-content-center">
        <div class="col-sm-4">
            <label for="storeSelect">{{ 'dashboard.search.store' | translate}}</label>
            <mcdo-store-select 
                id="storeSelect" 
                formControlName="stores"
                [multiple]="true"></mcdo-store-select>
            <div class="form-text">{{ 'dashboard.search.storeHint' | translate }}</div>
        </div>
        <div class="col-sm-4 border-start border-end">
            <div class="row">
                <div class="btn-group" role="group" id="toto">
                    <input type="radio" class="btn-check" id="radioEventDate" formControlName="dateType" [value]="DateType.event" checked>
                    <label class="btn btn-outline-primary" for="radioEventDate">{{ "dashboard.search.eventDate" | translate}}</label>
                    <input type="radio" class="btn-check" id="radioReservationDate" formControlName="dateType" [value]="DateType.booking">
                    <label class="btn btn-outline-primary" for="radioReservationDate">{{ "dashboard.search.reservationDate" | translate}}</label>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-6 mb-1">
                    <label for="beginDate">{{ 'dashboard.search.dateStart' | translate }}</label>
                    <mcdo-input-datepicker formControlName="startDate"></mcdo-input-datepicker>
                </div>
                <div class="col-sm-6 mb-1">
                    <label for="beginDate">{{ 'dashboard.search.dateEnd' | translate }}</label>
                    <mcdo-input-datepicker formControlName="endDate"></mcdo-input-datepicker>
                </div>
            </div>
        </div>
        <div class="col-sm-2" formGroupName="bookingChannel">
            <label>{{ "dashboard.search.bookingChannel.label" | translate }}</label>
            <div class="form-check">
                <input class="form-check-input" type="checkbox" id="chbxGuest" formControlName="guest" checked>
                <label class="form-check-label" for="chbxGuest">{{ 'dashboard.search.bookingChannel.guest' | translate }}</label>
            </div>
            <div class="form-check">
                <input class="form-check-input" type="checkbox" id="chbxTeleperformance" formControlName="teleperf" checked>
                <label class="form-check-label" for="chbxTeleperformance">{{ 'dashboard.search.bookingChannel.teleperf' | translate }}</label>
            </div>
            <div class="form-check">
                <input class="form-check-input" type="checkbox" id="chbxStore" formControlName="store" checked>
                <label class="form-check-label" for="chbxStore">{{ 'dashboard.search.bookingChannel.store' | translate }}</label>
            </div>
        </div>
    </div>
    <div class="row mt-4">
        <div class="col text-center">
            <button type="button" class="btn btn-primary me-3" (click)="generateDiagramms()">{{ "dashboard.search.buttons.charts" | translate }}</button>
            <Button type="button" class="btn btn-primary" (click)="generateCsv()">{{ "dashboard.search.buttons.csv" | translate }}</Button>
        </div>
    </div>
</form>
<hr>

@if(charts) {
    <div class="row">
        <h3>{{ "dashboard.stats.title" | translate }}</h3>
        @if (charts.length !== 0) {
            @for (chart of charts; track $index) {
                <div class="col-sm-6" echarts [options]="chart"></div>
            }
        } @else {
            <p>No data to show</p>
        }

    </div>
}
