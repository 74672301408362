import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { LegalCondition } from './legal-condition';

@Component({
    selector: 'mcdo-booking-validate',
    imports: [TranslateModule, CommonModule, FormsModule],
    templateUrl: './validate.component.html',
    styleUrl: './validate.component.css'
})
export class ValidateComponent implements OnInit {

  legalNoticeLink: string;

  @Input({ required: true })
  eventRecapTemplate: TemplateRef<void>;

  @Input({required: true})
  legalConditions: LegalCondition[];

  @Output()
  onConfirm = new EventEmitter();
  legalApproved: boolean;

  constructor(
    public translate: TranslateService
  ) { }

  ngOnInit(): void {
    this.translate.stream("booking-form.confirmation.legal-conditions.legalNoticeLink").subscribe(legalNoticeLink => this.legalNoticeLink = legalNoticeLink);
  }
}
