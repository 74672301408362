import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ApiConfig, RestService } from "common";
import { BirthdayTheme } from "../app/data/birthday-theme";

@Injectable({ providedIn: 'root' })
export class ThemeService extends RestService {
  constructor(
    protected httpClient: HttpClient,
    apiConfig: ApiConfig
  ) {
    super(apiConfig.bookingService);
  }

  public getThemes() {
    return this.httpClient.get<BirthdayTheme[]>(this.api("settings/birthday/themes"));
  }

  public getTheme(storeId: number, themeId: number) {
    return this.httpClient.get<BirthdayTheme>(this.api(`settings/store/${storeId}/themes/${themeId}`));
  }

}