export enum UserRole {
    ADMIN = 'ADMIN',
    TELEPERFORMANCE_ADMIN = 'TELEPERFORMANCE_ADMIN',
    RESTAURANT_MANAGER = 'RESTAURANT_MANAGER',
    FAMILY_MARKET = 'FAMILY_MARKET',
    TELEPERFORMANCE_OP = 'TELEPERFORMANCE_OP',
    RESTAURANT_HOST = 'RESTAURANT_HOST'
}

export const adminRole = ['ADMIN', 'TELEPERFORMANCE_ADMIN', 'RESTAURANT_MANAGER', 'FAMILY_MARKET']
