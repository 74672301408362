import { inject } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router
} from "@angular/router";
import { OidcSecurityService } from "angular-auth-oidc-client";
import { map, take } from "rxjs";

export const RoleGuard : CanActivateFn = (route: ActivatedRouteSnapshot) => {
  const oidcSecurityService = inject(OidcSecurityService);
  const router = inject(Router);

  return oidcSecurityService.getUserData().pipe(
    take(1),
    map(userData => {
      const userRoles: string[] = userData?.roles || [];
      const requiredRoles: string[] = route.data?.["roles"] || [];
    
      if (requiredRoles.length === 0 || requiredRoles.some(role => userRoles.includes(role))) {
        return true;
      }
    
      return router.parseUrl('');
    })
  );

}
