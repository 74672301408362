import { Component, Input, OnInit } from '@angular/core';
import { RouterLink } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faClock, faFlag, faSignOut, faUser, faWrench } from '@fortawesome/free-solid-svg-icons';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { NgxSpinnerModule } from 'ngx-spinner';
import { HasRolesDirective } from '../../directives/has-any-roles.directive';
import { IsAuthenticatedDirective } from '../../directives/is-authenticated.directive';
import { navbarMenu } from './navbarMenu';

@Component({
    selector: 'mcdo-navbar',
    imports: [NgbDropdownModule, TranslateModule, FontAwesomeModule, HasRolesDirective, IsAuthenticatedDirective, RouterLink, NgxSpinnerModule],
    templateUrl: './navbar.component.html',
    styleUrl: './navbar.component.css'
})
export class NavbarComponent implements OnInit {

  @Input()
  public applicationName: String
  @Input()
  public customMenus: navbarMenu[];

  faWrench = faWrench;
  faClock = faClock;
  faSignOut = faSignOut;
  faFlag = faFlag;
  faUser = faUser;

  collapsed = true;

  constructor(
    private oidcSecurityService: OidcSecurityService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.oidcSecurityService
    .checkAuth()
    .subscribe(x => {
      const lang = x?.userData?.lang as string || navigator.language.substring(0, 2);
      switch (lang) {
        case "de":
          this.translate.use("de-CH");
          break;
  
        case "it":
          this.translate.use("it-CH");
          break;
  
        default:
          this.translate.use(this.translate.defaultLang);
      }
    });
  }

  getCurrentUri() {
    return location.protocol + '//' + location.host + location.pathname;
  }

  logout() {
    this.oidcSecurityService
      .logoff()
      .subscribe();
  }

  changeLangClick(langKey: string) {
    this.translate.use(langKey);
  }

  get authorityServer():string {
    return this.oidcSecurityService.getConfigurations()[0].authority;
  }
}
