import { Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'mcdo-booking-confirmation',
    imports: [TranslateModule],
    templateUrl: './confirmation.component.html',
    styleUrl: './confirmation.component.css'
})
export class ConfirmationComponent {
  @Input({required: true})
  image: string;
}
