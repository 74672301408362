import { CommonModule } from '@angular/common';
import { Component, TemplateRef } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { EventImpl } from '@fullcalendar/core/internal';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { Booking } from '../../../../data/booking';
import { Store } from '../../../../data/store';

export class EventDetailsOptions<T extends Booking> {
  event: EventImpl;
  booking: T;
  bookingDetail: TemplateRef<any>
  store: Store;
}

export enum EventDetailsActions {
  Edit,
  Cancel,
  Close
}

@Component({
    selector: 'lib-event-details-modal',
    imports: [TranslateModule, CommonModule, ReactiveFormsModule],
    templateUrl: './event-details-modal.component.html',
    styleUrl: './event-details-modal.component.css'
})
export class EventDetailsModalComponent<T extends Booking> {
  EventDetailsActions = EventDetailsActions
  
  context = {
    store: this.eventDetailOptions.store,
    booking: this.eventDetailOptions.booking,
    event: this.eventDetailOptions.event
  };

  constructor(
    public activeModal: NgbActiveModal,
    public eventDetailOptions: EventDetailsOptions<T>
  ) {  }
}
